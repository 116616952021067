.ant-table-filter-column {
  flex-direction: row-reverse;
}
.ant-table-column-title {
  margin-left: 10px;
}
.ant-table-summary > tr > th,
.ant-table-summary > tr > td {
  text-align: right;
}

.ant-table-thead > tr > th {
  text-align: center !important;
}
